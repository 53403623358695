import React from "react"
import { graphql } from "gatsby"
import EventList from "../components/events/event-list.js"
import EventInfo from "../components/events/event-info.js"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

/**
 * ql_events: Contains the list of all Wet Ink events
 * plus the individual event descriptions based on
 * page slug
 */
export const ql_events = graphql`
  query {
    # List of events displayed on the left side of the page
    allContentfulEvent(sort: { fields: sortingDate, order: DESC }) {
      edges {
        node {
          title # Event title
          shortTitle # Short title
          slug # Event slug
          season # Season number
          image {
            file {
              url # Image URL
            }
          }
          sortingDate # Date for sorting
          date # Event Date/Time
          venue # Event venue name
          address # Address to venue
          price # Admission price
          info {
            # Event description
            json
          }
        }
      }
    }
  }
`
/**
 * Events (Default): Component for the content
 * of the event page
 */
const Events = props => {
  return (
    <Layout>
      <SEO title="Events" />
      <EventList season={props.data.allContentfulEvent.edges[0].node.season} eventData={props.data.allContentfulEvent.edges} />
      <EventInfo eventData={props.data.allContentfulEvent.edges[0].node} />
    </Layout>
  )
}

export default Events
