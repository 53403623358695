import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"

import Style from "./events.module.scss"

/**
 * EventInfo: The div that includes all the information
 * about an event onClick
 */
const EventInfo = props => {
  return (
    <div className={Style.infoPane}>
      <h2>INFO</h2>
      <hr />
      <h1>
        <center>
          <b>{props.eventData.title}</b>
        </center>
      </h1>
      <center>
        <i>
          {props.eventData.date.map((date, id) => {
            return (
              <p style={{ padding: "0", margin: "0" }} key={id}>
                {date}
              </p>
            )
          })}
        </i>
        <i>{props.eventData.venue}</i>
        <br />
        <i>{props.eventData.address}</i>
        <br />
        <i>{props.eventData.price}</i>
      </center>

      <center>___________</center>
      <center>
        {documentToReactComponents(props.eventData.info.json, event_option())}
      </center>
    </div>
  )
}

export default EventInfo

/* =================================================== */

/**
 * support_options: Default rich text parsing options
 * for the support page
 */
const event_option = () => {
  const option_node = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => {
        return (
          <img
            title={node.data.target.fields.title}
            style={{ paddingBottom: "1vw" }}
            src={`http:${node.data.target.fields.file["en-US"].url}`}
            alt={node.data.target.fields.title}
          />
        )
      },
      [BLOCKS.UL_LIST]: node => {
        return (
          <p style={{ margin: 0, padding: 0 }}>
            {node.content.map((listItem, key) => {
              return (
                <li style={{ margin: 0, padding: 0 }} key={key}>
                  {documentToReactComponents(listItem.content[0], _listNode)}
                </li>
              )
            })}
          </p>
        )
      },
    },
  }
  return option_node
}

/**
 * _listNode: Local rich text node to prevent paragraph
 * spacing in unordered lists
 */
const _listNode = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return <p style={{ margin: 0, padding: 0 }}>{children}</p>
    },
  },
}
