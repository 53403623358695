import React from "react"
import { Link } from "gatsby"

import Style from "./events.module.scss"

/**
 * EventList: The div that includes the entire
 * list of events as Link images
 */

const LeftArrow = props => {
    let previousSeason = (props.season !== 0) ? (props.season - 1): (props.season)
    if(props.event !== undefined)
    {
        return (
            <Link className={Style.pastEventLinkLeft} to={`/events/season-${previousSeason}/${props.event}`}><h2>←</h2></Link>
         )
    } else {
        return (
            <Link className={Style.pastEventLinkLeft} to={`/events/season-${previousSeason}/${props.event}`}><h2 style={{visibility: "hidden"}}>←</h2></Link>
         )
    }
}

const RightArrow = props => {
    let nextSeason = props.season + 1
        if(props.event !== undefined)
        {
            return (
                <Link className={Style.pastEventLinkRight} to={`/events/season-${nextSeason}/${props.event}`}><h2>→</h2></Link>
            ) 
        } else {
            return (
                <Link className={Style.pastEventLinkRight} to={`/events/season-${nextSeason}/${props.event}`}><h2 style={{visibility: "hidden"}}>→</h2></Link>
            )
        }
}

const EventList = props => {
  let previousHeaderEvent, nextHeaderEvent
    console.log(props.eventData)
  props.eventData.sort(function (a, b) {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return new Date(b.date) - new Date(a.date)
  })

  // Find first event of prevoius or next season
  props.eventData.slice(0).reverse().map((events, key) => {
      if(events.node.season === (props.season - 1))
      {previousHeaderEvent = events.node.slug}
      else if(events.node.season === (props.season + 1))
      {nextHeaderEvent = events.node.slug}

      return 0;
  })

  return (
    <div className={Style.eventListPane}>
    
      <h2>
        <div className={Style.seasonBanner}>
        <LeftArrow season={props.season} event={previousHeaderEvent}/>
             SEASON {props.season}
        <RightArrow season={props.season} event={nextHeaderEvent}/>
      </div>
      </h2>

      <hr className={Style.line}/>

      {props.eventData.map((events, key) => {
        if (events.node.season === props.season) {
          return (
            <Link
              to={`/events/season-${events.node.season}/${events.node.slug}`}
              key={key}
            >
              <EventImage
                src={events.node.image.file.url}
                alt={events.node.image.shortTitle}
                shortTitle={events.node.shortTitle}
              />
            </Link>
          )
        } else return null
      })}
    </div>
  )
}

export default EventList

/* ===================================================*/

/**
 * EventImage: A single event image, including
 * link, short title, and hovering functionality
 */
const EventImage = props => {
  const { src, alt, shortTitle } = props

  return (
    <div>
      <div className={Style.eventImageContainer}>
        <img src={"http:" + src} alt={alt} className={Style.eventImage} />
        <div className={Style.eventImageTitleContainer}>
          <div className={Style.eventImageTitle}>
            <hr color="black" />
            {shortTitle.toUpperCase()}
            <hr color="black" />
          </div>
        </div>
      </div>
    </div>
  )
}
